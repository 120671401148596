<template>
  <div class="page">
    <div
      class="back-link"
      @click="$router.push({ name: 'Accounts' })"
    >
      <b-icon-chevron-left font-scale="1.3" />
      <span>
        {{ capitalizeFirstLetter($t('words.accountsManagement')) }}
      </span>
    </div>
    <div>
      <div class="top">
        <b-row>
          <b-col class="left-col">
            <span>
              <img
                src="@/assets/icons/face_user.svg"
                alt="Icon face user"
              >
              {{ usersCount }} {{ $tc('words.user', usersCount) }}
            </span>
            <div
              class="add-btn"
              @click="$router.push({ name: 'AddUser' })"
            >
              <b-icon-plus />
              {{ $t('users.add') }}
            </div>
          </b-col>
          <b-col class="right-col">
            <TableFilters
              :filter-name="'status__in'"
              :choices="userStatuses"
              @filter="setTableFilters"
            />
          </b-col>
        </b-row>
      </div>
      <SearchablePaginatedTable
        :name="'usersListTable'"
        :loading="loadingTable"
        :searchable="true"
        :search-placeholder="$t('users.search')"
        :search-function="SEARCH_USERS_LIST"
        :route-to="'UserDetail'"
        :fields="fields"
        :rows="rows"
        :count="usersCount"
        :default-sorting="{
          sortedUp: false,
          sortedDown: true,
          sortedField: 'date_joined'
        }"
        :sort-function="GET_USERS_LIST"
        :selectable="false"
        @sorted="setTableSorted"
        @change-page="changeTablePage"
        @delete="openDeleteModal"
      />
    </div>
  </div>
</template>

<script>
import SearchablePaginatedTable from '@/components/SearchablePaginatedTable/Layout.vue';
import TableFilters from '@/components/TableFilters.vue';

import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'Users',

  components: {
    SearchablePaginatedTable,
    TableFilters
  },

  data() {
    return {
      loadingTable: false,
      addingUser: false,
      deletingUser: false,
      userToDelete: null,
      tableSorted: {
        direction: '-',
        field: 'date_joined'
      }
    };
  },

  computed: {
    ...mapState('table', [
      'currentTables'
    ]),
    ...mapState('users', [
      'usersList',
      'usersCount',
      'isUsersListSearched',
      'searchUsersFilter',
      'userStatuses'
    ]),
    ...mapState('usergroups', [
      'usergroupsRoles'
    ]),

    fields() {
      return [
        {
          key: this.$t('users.table.fields.lastname'),
          apiName: 'last_name',
          sortable: true,
          width: '9%'
        },
        {
          key: this.$t('users.table.fields.firstname'),
          apiName: 'first_name',
          sortable: true,
          width: '9%'
        },
        {
          key: this.$t('users.table.fields.id'),
          apiName: 'username',
          sortable: true,
          width: '9%'
        },
        {
          key: this.$t('users.table.fields.organisation'),
          sortable: false,
          width: '13%'
        },
        {
          key: this.$t('users.table.fields.registrationDate'),
          apiName: 'date_joined',
          sortable: true,
          centered: true,
          width: '14%'
        },
        {
          key: this.$t('users.table.fields.loginDate'),
          apiName: 'last_login',
          sortable: true,
          centered: true,
          width: '15%'
        },
        {
          key: this.$t('users.table.fields.status'),
          sortable: false,
          centered: true,
          width: '6%'
        },
        {
          key: this.$t('users.table.fields.admin'),
          sortable: false,
          centered: true,
          width: '6%'
        },
        {
          key: this.$t('users.table.fields.delete'),
          sortable: false,
          centered: true,
          width: '8%'
        }
      ];
    },
    rows() {
      return this.usersList.map(el => {
        return {
          id: el.id,
          [this.$t('users.table.fields.lastname')]: el.last_name.toUpperCase(),
          [this.$t('users.table.fields.firstname')]: el.first_name,
          [this.$t('users.table.fields.id')]: el.username,
          [this.$t('users.table.fields.organisation')]:
            el.usergroup_roles
              .filter(el => el.usergroup.usergroup_type.codename === 'organisation')
              .map(usergroupEl => usergroupEl.usergroup.display_name).join(', ')
              .concat(
                el.usergroup_roles.filter(el => el.usergroup.usergroup_type.codename === 'organisation').length &&
                el.usergroup_roles.filter(el => el.usergroup.usergroup_type.codename !== 'organisation').length ?
                  ', ' :
                  '',
                el.usergroup_roles
                  .filter(el => el.usergroup.usergroup_type.codename !== 'organisation')
                  .map(usergroupEl => usergroupEl.usergroup.display_name).join(', ')
              ),
          [this.$t('users.table.fields.registrationDate')]:
            el.date_joined ? new Date(el.date_joined).toLocaleString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' }) : '',
          [this.$t('users.table.fields.loginDate')]:
            el.last_login ? new Date(el.last_login).toLocaleString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' }) : '',
          [this.$t('users.table.fields.status')]:
            this.userStatuses.find(item => item.choice === el.status) ?
              this.userStatuses.find(item => item.choice === el.status).choice :
              '-1',
          [this.$t('users.table.fields.admin')]: el.is_superuser
        };
      });
    }
  },

  created() {
    this.SET_TABLE({
      name: 'usersListTable',
      table: {
        loading: false,
        currentPage: this.currentTables.find(t => t.name === 'usersListTable') ?
          this.currentTables.find(t => t.name === 'usersListTable').currentPage :
          1,
        isTableSearched: false,
        searchQuery: null
      }
    });
  },

  mounted() {
    // Reset filters
    this.setTableFilters({
      filter: 'status__in',
      values: this.userStatuses.map(el => {
        return {
          ...el,
          state: el.choice === 2 ? true : false
        };
      })
    }, true);
    // Get data if data not loaded already
    if (this.usergroupsRoles.length === 0) {
      this.getConfigData();
    }
    if (this.usersList.length === 0 || this.isUsersListSearched) {
      this.getData();
    }

    // Watch modal closing event
    this.$root.$on('bv::modal::hide', () => {
      this.addingUser = false;
      this.deletingUser = false;
    });
  },

  methods: {
    ...mapMutations('table', [
      'SET_TABLE'
    ]),
    ...mapMutations('modal', [
      'OPEN_MODAL',
    ]),
    ...mapActions('users', [
      'GET_USERS_LIST',
      'SEARCH_USERS_LIST',
      'PRESET_USERS_FILTERS',
      'PREREMOVE_USERS_FILTERS',
      'DELETE_USER'
    ]),
    ...mapActions('organisations', [
      'GET_ORGANISATIONS_TYPES'
    ]),
    ...mapActions('usergroups', [
      'GET_USERGROUPS_ROLES'
    ]),

    getConfigData() {
      this.GET_ORGANISATIONS_TYPES();
      this.GET_USERGROUPS_ROLES();
    },

    getData(page) {
      this.loadingTable = true;
      this.GET_USERS_LIST({
        ...this.tableSorted,
        page: page
      })
        .then(() => {
          this.loadingTable = false;
        })
        .catch(() => {
          this.loadingTable = false;
        });
    },

    openDeleteModal(e) {
      this.userToDelete = e;
      this.OPEN_MODAL({
        modal: 'confirmation',
        open: true,
        title: this.$t('modals.deleteUser.title'),
        content: this.$t('modals.deleteUser.content', {
          user: `${this.userToDelete[this.$t('users.table.fields.firstname')]} ${this.userToDelete[this.$t('users.table.fields.lastname')]}`
        }),
        trigger: this.deleteUser
      });
    },
    deleteUser() {
      this.loadingTable = true;
      this.DELETE_USER(this.userToDelete)
        .then(() => {
          this.getData();
        })
        .catch(() => {
          this.loadingTable = false;
        });
    },

    changeTablePage(e) {
      this.loadingTable = true;
      this.GET_USERS_LIST({
        ...this.tableSorted,
        page: e
      })
        .then(() => {
          this.loadingTable = false;
        })
        .catch(() => {
          this.loadingTable = false;
        });
    },

    setTableSorted(e) {
      this.tableSorted = e;
    },

    setTableFilters(e, reset = false) {
      for (const filter of e.values) {
        if (filter.state) {
          this.PRESET_USERS_FILTERS({
            filter: e.filter,
            value: filter.choice
          });
        } else {
          this.PREREMOVE_USERS_FILTERS({
            filter: e.filter,
            value: filter.choice
          });
        }
      }
      if (!reset) {
        this.getData();
      }
    }
  }
};
</script>
